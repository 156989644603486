import request from '@/utils/request'

// 综合体服务数据
export const getSyntheses = (code) =>
  request({
    url: '/hp/platform/data/v1/screen/statistics/syntheses',
    method: 'get',
    params: {
      code,
    },
  })

// 统计数据集合
export const getStatistics = (code) =>
  request({
    url: '/hp/platform/data/v1/screen/mock-data?level=1',
    method: 'get',
    params: {
      districtCode: code,
    },
  })
