<template>
  <div class="main">
    <div class="older-age">
      <div class="outer-padding box-contain">
        <div class="age-item">
          <div>{{ areaRatinAge.olderInfo.age.betweenSixtyAndEighty }}</div>
          <div>60-80岁</div>
        </div>
        <div class="age-item">
          <div>{{ areaRatinAge.olderInfo.age.betweenEightyAndNinety }}</div>
          <div>80-90岁</div>
        </div>
        <div class="age-item">
          <div>{{ areaRatinAge.olderInfo.age.betweenNinetyAndOneHundred }}</div>
          <div>90-100岁</div>
        </div>
        <div class="age-item">
          <div>{{ areaRatinAge.olderInfo.age.overOneHundred }}</div>
          <div>100岁以上</div>
        </div>
      </div>
    </div>
    <div class="older-administration">
      <div id="chart"></div>
    </div>
    <div class="older-health">
      <div class="outer-padding health-type">
        <div>
          <span>高龄</span>
          <span style="color: #32cf57"
            >{{ areaRatinAge.olderInfo.type.advanced }}
          </span>
        </div>
        <div>
          <span>残疾</span>
          <span>{{ areaRatinAge.olderInfo.type.deformity }} </span>
        </div>
        <div>
          <span>留守</span>
          <span style="color: #ff3c1d"
            >{{ areaRatinAge.olderInfo.type.alone }}
          </span>
        </div>
        <div>
          <span>特困</span>
          <span style="color: #b620e0"
            >{{ areaRatinAge.olderInfo.type.needy }}
          </span>
        </div>
      </div>
    </div>
    <div class="older-ability">
      <div class="outer-padding box-contain">
        <div class="age-item">
          <div>{{ areaRatinAge.olderInfo.assessment.selfCareCount }}</div>
          <div>自理</div>
        </div>
        <div class="age-item">
          <div>{{ areaRatinAge.olderInfo.assessment.mediatedCareCount }}</div>
          <div>介助</div>
        </div>
        <div class="age-item">
          <div>{{ areaRatinAge.olderInfo.assessment.mediatedNurseCount }}</div>
          <div>介护</div>
        </div>
        <div class="age-item">
          <div>{{ areaRatinAge.olderInfo.assessment.specialNurseCount }}</div>
          <div>特护</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
import { getStatistics } from '@/api/homePage/left.js'
export default {
  data() {
    return {
      areaRatinAge: {
        olderInfo: {
          age: {
            betweenSixtyAndEighty: 0, // 60-80
            betweenEightyAndNinety: 0, // 80-90
            betweenNinetyAndOneHundred: 0, // 90-100
            overOneHundred: 0, // 100以上
          },
          regional: [], // 老人人口行政分布
          type: {
            healthyCount: 0, // 健康人数
            subHealthyCount: 0, // 亚健康人数
            diseaseCount: 0, // 疾病人数
          },
          assessment: {
            selfCareCount: 0, // 自理
            mediatedCareCount: 0, // 介助
            mediatedNurseCount: 0, // 介护
            specialNurseCount: 0, // 特护
          },
        },
      },
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
  mounted() {
    this.getStatistics()
  },
  methods: {
    getStatistics() {
      getStatistics(this.userInfo.areaCode).then((res) => {
        if (res.code == 0) {
          this.areaRatinAge = res.data
          this.getAreaOlderMessage()
        }
      })
    },
    getAreaOlderMessage() {
      var data
      if (this.areaRatinAge.olderInfo.regional.length === 0) {
        data = [
          {
            name: '暂无数据',
            value: 0,
          },
        ]
      } else {
        data = this.areaRatinAge.olderInfo.regional.map((item) => {
          const copy = item
          return { name: copy.streetName, value: copy.populationRatio }
        })
      }
      this.$nextTick(() => {
        var chart = echarts.init(document.getElementById('chart'))
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {d}%',
          },
          series: [
            {
              label: {
                normal: {
                  show: true,
                  fontSize: 8,
                },
              },
              labelLine: {
                normal: {
                  show: true,
                },
              },
              type: 'pie',
              radius: '55%',
              center: ['50%', '50%'],
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ],
        }
        chart.setOption(option)
        window.addEventListener('resize', function () {
          chart.resize()
        })
      })
    },
  },
}
</script>

<style scoped>
.main {
  margin-top: 0.42rem;
  display: grid;
  grid-template-columns: repeat(2, 11.8rem);
  gap: 0.42rem;
}
.older-age {
  background: url('~@/assets/images/homePage_images/olderAgeBg.png');
  background-size: 100% 100%;
  height: 8.83rem;
  width: 100%;
}
.older-ability {
  background: url('~@/assets/images/homePage_images/abilityBg.png');
  background-size: 100% 100%;
  height: 8.83rem;
  width: 100%;
}

.outer-padding {
  margin-top: 1.33rem;
  margin-left: 0.3rem;
}

.box-contain {
  display: grid;
  grid-template-columns: repeat(2, 5.42rem);
  grid-template-rows: repeat(2, 3.33rem);
  gap: 0.3rem;
}

.age-item {
  width: 100%;
  height: 3.33rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
}
.age-item :first-child {
  width: fit-content;
  margin: 0 auto;
  font-size: 1.25rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #b4d3fe;
  line-height: 1.56rem;
  margin-top: 0.46rem;
  margin-bottom: 0.33rem;
}
.age-item :last-child {
  width: fit-content;
  margin: 0 auto;
  font-size: 0.58rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 0.67rem;
}
.older-administration {
  background: url('~@/assets/images/homePage_images/administrationBg.png');
  background-size: 100% 100%;
  height: 8.83rem;
  width: 100%;
}
#chart {
  width: 11.1rem;
  height: 7rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
  margin: 1.33rem 0.3rem 0.5rem;
}
.older-health {
  background: url('~@/assets/images/homePage_images/healthBg.png');
  background-size: 100% 100%;
  height: 8.83rem;
  width: 100%;
}
.health-type {
  width: 11rem;
  height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.health-type div {
  padding: 0 0.5rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 0.08rem;
  border: 0.04rem solid #213060;
  height: 1.5rem;
  width: calc(100% - 1rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.health-type div :first-child {
  font-size: 0.58rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #69b1ff;
  line-height: 0.83rem;
}
.health-type div :last-child {
  font-size: 1.25rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ff8529;
  line-height: 1.45rem;
}
</style>
